var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-overlay",
            {
              attrs: { show: _vm.isLoading, rounded: "lg", opacity: "0.75" },
              on: {
                "update:show": function ($event) {
                  _vm.isLoading = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("loading", {
                            attrs: {
                              active: _vm.isLoading,
                              loader: "bars",
                              color: "#00A09C",
                              width: 64,
                            },
                            on: {
                              "update:active": function ($event) {
                                _vm.isLoading = $event
                              },
                            },
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "loading-text",
                              attrs: { id: "cancel-label" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(296, "Please wait...")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.$screen.width <= 576
                ? _c(
                    "div",
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "w-100",
                          attrs: {
                            show: _vm.isBusyDataTable,
                            rounded: "lg",
                            opacity: "0.75",
                          },
                          on: {
                            "update:show": function ($event) {
                              _vm.isBusyDataTable = $event
                            },
                          },
                        },
                        [
                          _c("list-my-documents", {
                            attrs: { documents: _vm.Docs },
                            on: {
                              "list-my-document:view-document":
                                _vm.viewDocument,
                              "list-my-document:sign":
                                _vm.handleListMyDocumentSign,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("transition", { attrs: { name: "slide" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "container-layout px-0 animated fadeIn",
                          },
                          [
                            _c(
                              "b-card",
                              { staticClass: "card-border-blue-light" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-overlay",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          show: _vm.isBusyDataTable,
                                          rounded: "lg",
                                          opacity: "0.75",
                                        },
                                        on: {
                                          "update:show": function ($event) {
                                            _vm.isBusyDataTable = $event
                                          },
                                        },
                                      },
                                      [
                                        _vm.Docs.length > 0
                                          ? _c(
                                              "b-col",
                                              {
                                                attrs: { cols: "12", xl: "12" },
                                              },
                                              [
                                                _vm.$screen.width < 992
                                                  ? _c("CardListBuilder", {
                                                      attrs: {
                                                        items: _vm.Docs,
                                                        fields:
                                                          _vm.docMobileFields,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "badges",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c("span", {
                                                                  directives: [
                                                                    {
                                                                      name: "tooltip",
                                                                      rawName:
                                                                        "v-tooltip.top",
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .comments,
                                                                      expression:
                                                                        "data.item.comments",
                                                                      modifiers:
                                                                        {
                                                                          top: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  class: `badge badge-${data.item.validatedClass}`,
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        _vm.validatedStatus(
                                                                          data
                                                                            .item
                                                                            .status
                                                                        )
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "actions",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      size: "sm",
                                                                      variant:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.rowClicked(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "icon-eye",
                                                                    }),
                                                                  ]
                                                                ),
                                                                data.item
                                                                  .fileName
                                                                  .length > 0
                                                                  ? _c(
                                                                      "b-button",
                                                                      {
                                                                        attrs: {
                                                                          variant:
                                                                            "primary",
                                                                          size: "sm",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewDocument(
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "icon-cloud-download",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                data.item
                                                                  .status ==
                                                                  3 &&
                                                                data.item.toSign
                                                                  ? _c(
                                                                      "b-button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-modal",
                                                                              rawName:
                                                                                "v-b-modal.askPassword",
                                                                              modifiers:
                                                                                {
                                                                                  askPassword: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          size: "sm",
                                                                          variant:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.signDocument(
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "icon-tag",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                data.item
                                                                  .status ==
                                                                  2 &&
                                                                data.item.toSign
                                                                  ? _c(
                                                                      "b-button",
                                                                      {
                                                                        attrs: {
                                                                          variant:
                                                                            "primary",
                                                                          size: "sm",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewDocument(
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "icon-cloud-download",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        132748049
                                                      ),
                                                    })
                                                  : _vm._e(),
                                                _vm.$screen.width >= 992
                                                  ? _c(
                                                      "b-row",
                                                      { staticClass: "form" },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass: "pb-2",
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-input-group",
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          48,
                                                                          "Type to search ..."
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.filter,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.filter =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "filter",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticClass:
                                                                          "btn-search",
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .filter
                                                                          .length ===
                                                                        0
                                                                          ? _c(
                                                                              _vm.getIconLucide(
                                                                                "Search"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                staticClass:
                                                                                  "icon",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#FFFFFF",
                                                                                    size: 16,
                                                                                    "stroke-width": 2.5,
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              _vm.getIconLucide(
                                                                                "X"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                staticClass:
                                                                                  "icon",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#FFFFFF",
                                                                                    size: 16,
                                                                                    "stroke-width": 2.5,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.filter =
                                                                                        ""
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$screen.width >= 992
                                                  ? _c("b-table", {
                                                      ref: "myTable",
                                                      attrs: {
                                                        hover: _vm.hover,
                                                        responsive: "sm",
                                                        selectable: "",
                                                        "selected-variant":
                                                          _vm.selectedColor,
                                                        "select-mode":
                                                          _vm.selectMode,
                                                        items: _vm.Docs,
                                                        fields: _vm.docfields,
                                                        "current-page":
                                                          _vm.currentPage,
                                                        filter: _vm.filter,
                                                        "per-page": _vm.perPage,
                                                        "sticky-header":
                                                          "900px",
                                                        bordered: "",
                                                        small: "",
                                                        "head-variant": _vm.hv,
                                                      },
                                                      on: {
                                                        "row-clicked":
                                                          _vm.rowClicked,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "cell(status)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wrap-status",
                                                                  },
                                                                  [
                                                                    data.item
                                                                      .comments
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "tooltip",
                                                                                  rawName:
                                                                                    "v-tooltip.top",
                                                                                  value:
                                                                                    data
                                                                                      .item
                                                                                      .comments,
                                                                                  expression:
                                                                                    "data.item.comments",
                                                                                  modifiers:
                                                                                    {
                                                                                      top: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            class: `status ${data.item.validatedClass}`,
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "0.7rem",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  data.value
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            class: `status ${data.item.validatedClass}`,
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "0.7rem",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  data.value
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(name)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]),
                                                                data.item
                                                                  .type ===
                                                                  11 ||
                                                                data.item
                                                                  .type === 9
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.dateToString(
                                                                                data
                                                                                  .item
                                                                                  .deliveryContractStrDate
                                                                              )
                                                                            ) +
                                                                            " -\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.dateToString(
                                                                                data
                                                                                  .item
                                                                                  .deliveryContractEndDate
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(sign)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                data.item
                                                                  .status ===
                                                                  3 &&
                                                                data.item.toSign
                                                                  ? _c(
                                                                      _vm.getIconLucide(
                                                                        "FileSignature"
                                                                      ),
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-modal",
                                                                              rawName:
                                                                                "v-b-modal.askPassword",
                                                                              modifiers:
                                                                                {
                                                                                  askPassword: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "#06263E",
                                                                          size: 20,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.signDocument(
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(viewDocument)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    _vm.ICONS
                                                                      .EYE.name
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .ICONS
                                                                          .EYE
                                                                          .color,
                                                                      size: 20,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePreviewDocument(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(edit)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    _vm.ICONS
                                                                      .EDIT.name
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .ICONS
                                                                          .EDIT
                                                                          .color,
                                                                      size: 20,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.viewDocumentDetail(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3756253654
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c("b-col", [
                                              _c(
                                                "div",
                                                { staticClass: "empty-state" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          5528,
                                                          "Currently you have no document"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
              _c("modal-for-sign-document", {
                attrs: {
                  open: _vm.showModalForSignDocument,
                  document: _vm.documentSelected,
                  "requester-id": _vm.requesterId,
                  "user-id": _vm.userId,
                },
                on: {
                  "modal-for-sign-document:document-signed":
                    _vm.handleModalForSignDocumentDocumentSigned,
                  "modal-for-sign-document:close":
                    _vm.handleModalForSignDocumentClose,
                },
              }),
              _c(
                "b-modal",
                {
                  staticClass: "modal-success",
                  attrs: {
                    "header-class": "header-class-modal-doc-package",
                    title: _vm.FormMSG(120, "Success!"),
                    "ok-variant": "success",
                    "ok-only": "",
                  },
                  on: {
                    ok: function ($event) {
                      _vm.successSignModal = false
                    },
                  },
                  model: {
                    value: _vm.successSignModal,
                    callback: function ($$v) {
                      _vm.successSignModal = $$v
                    },
                    expression: "successSignModal",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(122, "Your document has been signed.")
                      ) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _c("oodrive-sign-contract-modal", {
                attrs: {
                  url: _vm.urlOodrive,
                  "delivery-id": _vm.documentSelected
                    ? _vm.documentSelected.firstDeliveryId
                    : 0,
                  "doc-signed-xid": _vm.documentSelected
                    ? _vm.documentSelected.docSignedXid
                    : "",
                },
                on: {
                  "oodrive-sign-contract-modal:close":
                    _vm.onOodriveSignContractClosed,
                  "oodrive-sign-contract-modal:documentUpdateStatus":
                    _vm.onOodriveSignContractStatusUpdated,
                },
                model: {
                  value: _vm.isOodriveSignContractOpen,
                  callback: function ($$v) {
                    _vm.isOodriveSignContractOpen = $$v
                  },
                  expression: "isOodriveSignContractOpen",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }