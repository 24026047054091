var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container-mobile animated fadeIn",
      staticStyle: { top: "95px" },
    },
    [
      _c(
        "div",
        { staticClass: "content-contact-mobile content-document-mobile form" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  { staticClass: "form-input-search" },
                  [
                    _c("b-form-input", {
                      staticClass: "search",
                      attrs: {
                        type: "text",
                        placeholder: _vm.FormMSG(1, "Type to search"),
                      },
                      on: { input: _vm.handleInputFilter },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-input-icon-search",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.clearFilter },
                  },
                  [
                    _vm.filter === ""
                      ? _c("search", { attrs: { color: "#06263E", size: 16 } })
                      : _c("x", { attrs: { color: "#06263E", size: 16 } }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "16px" } },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-select",
                    {
                      staticClass: "mb-3",
                      on: { change: _vm.handleChangeDocType },
                      model: {
                        value: _vm.docTypeSelected,
                        callback: function ($$v) {
                          _vm.docTypeSelected = $$v
                        },
                        expression: "docTypeSelected",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v(_vm._s(_vm.FormMSG(4, "All"))),
                      ]),
                      _vm._l(_vm.docTypes, function (docType, index) {
                        return _c(
                          "b-form-select-option",
                          { key: index, attrs: { value: docType.value } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(docType.text) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "16px" } },
            [
              _c("b-col", [
                _c("div", { staticClass: "title-list" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.FormMSG(2, "Files")))]),
                ]),
              ]),
            ],
            1
          ),
          _vm.docs.length === 0
            ? _c("div", { staticClass: "text-center" }, [
                _c("p", [_vm._v(_vm._s(_vm.FormMSG(3, "No result")))]),
              ])
            : _vm._e(),
          _vm._l(_vm.docs, function (doc, index) {
            return _c(
              "div",
              { key: index },
              [
                index > 0
                  ? _c("div", { staticClass: "divider-line mt-3 mb-2" })
                  : _vm._e(),
                _c(
                  "b-row",
                  { class: { "mt-3": index === 0 } },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "pr-0 d-flex flex-column justify-content-start",
                        attrs: { cols: "10" },
                      },
                      [
                        _c("p", { staticClass: "name-contact mt-1 mb-0" }, [
                          _c("label", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(doc.name) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                        ]),
                        doc.type === 11 || doc.type === 9
                          ? _c("p", { staticClass: "mb-1" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.dateToString(
                                      doc.deliveryContractStrDate
                                    )
                                  ) +
                                  " -\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.dateToString(
                                      doc.deliveryContractEndDate
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _c("p", { staticClass: "mt-2 mb-0" }, [
                          _c("span", { staticClass: "status info" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.getDocType(doc.type)) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                          _c("span", { staticClass: "divider-dot" }),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.getPublishedOn(doc.publishedOn)) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            doc.comments
                              ? _c(
                                  "Button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.focus",
                                        value: doc.comments,
                                        expression: "doc.comments",
                                        modifiers: { focus: true },
                                      },
                                    ],
                                    staticClass: "mt-3 mb-0 p-0 p-button-text",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "wrap-status d-flex justify-content-start",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: `status ${doc.validatedClass}`,
                                            staticStyle: {
                                              "font-size": "0.7rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.validatedStatus(
                                                    doc.status
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "Button",
                                  {
                                    staticClass: "mt-3 mb-0 p-0 p-button-text",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "wrap-status d-flex justify-content-start",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: `status ${doc.validatedClass}`,
                                            staticStyle: {
                                              "font-size": "0.7rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.validatedStatus(
                                                    doc.status
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "d-flex align-items-start",
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              variant: "light",
                              "toggle-class": "transparent",
                              "no-caret": "",
                              dropleft: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("MoreVertical", {
                                        attrs: { color: "#06263E", size: 20 },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDocument(doc)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-start align-items-center w-100",
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Eye"), {
                                      tag: "component",
                                      attrs: { color: "#06263E", size: 16 },
                                    }),
                                    _c("div", { staticClass: "pl-2" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(7, "View document")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDocumentDetail(doc)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-start align-items-center w-100",
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Edit2"), {
                                      tag: "component",
                                      attrs: { color: "#06263E", size: 16 },
                                    }),
                                    _c("div", { staticClass: "pl-2" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(5, "See Details")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            doc.status === 3 && doc.toSign
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickSign(doc)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start align-items-center w-100",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("FileSignature"), {
                                          tag: "component",
                                          attrs: { color: "#06263E", size: 16 },
                                        }),
                                        _c("div", { staticClass: "pl-2" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(15, "Sign")) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }