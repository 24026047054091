<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-overlay :show.sync="isLoading" rounded="lg" opacity="0.75">
				<div v-if="$screen.width <= 576">
					<b-overlay :show.sync="isBusyDataTable" rounded="lg" class="w-100" opacity="0.75">
						<list-my-documents :documents="Docs" @list-my-document:view-document="viewDocument" @list-my-document:sign="handleListMyDocumentSign" />
					</b-overlay>
				</div>
				<div v-else>
					<transition name="slide">
						<div class="container-layout px-0 animated fadeIn">
							<b-card class="card-border-blue-light">
								<b-row>
									<b-overlay :show.sync="isBusyDataTable" rounded="lg" class="w-100" opacity="0.75">
										<b-col v-if="Docs.length > 0" cols="12" xl="12">
											<CardListBuilder v-if="$screen.width < 992" :items="Docs" :fields="docMobileFields">
												<template slot="badges" slot-scope="data">
													<span
														:class="`badge badge-${data.item.validatedClass}`"
														v-tooltip.top="data.item.comments"
														v-html="validatedStatus(data.item.status)"
													/>
												</template>
												<template slot="actions" slot-scope="data">
													<b-button size="sm" variant="primary" class="mr-2" @click="rowClicked(data.item)">
														<i class="icon-eye" />
													</b-button>
													<b-button variant="primary" size="sm" v-if="data.item.fileName.length > 0" @click="viewDocument(data.item)">
														<i class="icon-cloud-download" />
													</b-button>
													<b-button
														v-if="data.item.status == 3 && data.item.toSign"
														size="sm"
														variant="primary"
														v-b-modal.askPassword
														@click="signDocument(data.item)"
													>
														<i class="icon-tag" />
													</b-button>
													<b-button
														variant="primary"
														size="sm"
														v-if="data.item.status == 2 && data.item.toSign"
														@click="viewDocument(data.item)"
													>
														<i class="icon-cloud-download" />
													</b-button>
												</template>
											</CardListBuilder>
											<b-row v-if="$screen.width >= 992" class="form">
												<b-col sm="12" md="12" lg="6" xl="6" class="pb-2">
													<b-input-group>
														<b-form-input type="text" v-model="filter" :placeholder="FormMSG(48, 'Type to search ...')" />
														<b-input-group-append class="cursor-pointer">
															<b-input-group-text class="btn-search">
																<component
																	:is="getIconLucide('Search')"
																	color="#FFFFFF"
																	:size="16"
																	class="icon"
																	:stroke-width="2.5"
																	v-if="filter.length === 0"
																/>
																<component
																	:is="getIconLucide('X')"
																	color="#FFFFFF"
																	:size="16"
																	class="icon"
																	:stroke-width="2.5"
																	@click="filter = ''"
																	v-else
																/>
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
												</b-col>
											</b-row>
											<!-- <pre>{{ Docs }}</pre> -->
											<b-table
												v-if="$screen.width >= 992"
												:hover="hover"
												ref="myTable"
												responsive="sm"
												selectable
												:selected-variant="selectedColor"
												:select-mode="selectMode"
												:items="Docs"
												:fields="docfields"
												:current-page="currentPage"
												:filter="filter"
												:per-page="perPage"
												sticky-header="900px"
												bordered
												small
												:head-variant="hv"
												@row-clicked="rowClicked"
											>
												<template #cell(status)="data">
													<div class="wrap-status">
														<div
															v-if="data.item.comments"
															:class="`status ${data.item.validatedClass}`"
															v-tooltip.top="data.item.comments"
															style="font-size: 0.7rem"
														>
															{{ data.value }}
														</div>
														<div v-else :class="`status ${data.item.validatedClass}`" style="font-size: 0.7rem">
															{{ data.value }}
														</div>
													</div>
												</template>
												<!-- <template #cell(senderStatus)="data">
													<div
														v-if="data.item.deliveryCurrentStep > 0 && data.item.requireManagerApproval && data.item.comments"
														v-tooltip.top="data.item.comments"
														class="wrap-status"
													>
														<div :class="`status ${data.item.prodValidatedClass}`" style="font-size: 0.7rem">
															{{ data.value }}
														</div>
													</div>
													<div v-else-if="data.item.deliveryCurrentStep > 0 && data.item.requireManagerApproval" class="wrap-status">
														<div :class="`status ${data.item.prodValidatedClass}`" style="font-size: 0.7rem">
															{{ data.value }}
														</div>
													</div>
												</template> -->
												<template #cell(name)="data">
													<div>{{ data.item.name }}</div>
													<div v-if="data.item.type === 11 || data.item.type === 9" class="font-weight-bold">
														{{ dateToString(data.item.deliveryContractStrDate) }} -
														{{ dateToString(data.item.deliveryContractEndDate) }}
													</div>
												</template>
												<template #cell(sign)="data">
													<component
														:is="getIconLucide('FileSignature')"
														color="#06263E"
														:size="20"
														v-b-modal.askPassword
														v-if="data.item.status === 3 && data.item.toSign"
														@click="signDocument(data.item)"
													/>
												</template>
												<template #cell(viewDocument)="data">
													<component
														:is="getIconLucide(ICONS.EYE.name)"
														:color="ICONS.EYE.color"
														:size="20"
														@click="handlePreviewDocument(data.item)"
													/>
												</template>
												<template #cell(edit)="data">
													<component
														:is="getIconLucide(ICONS.EDIT.name)"
														:color="ICONS.EDIT.color"
														:size="20"
														@click="viewDocumentDetail(data.item)"
													/>
												</template>
											</b-table>
										</b-col>
										<b-col v-else>
											<div class="empty-state">
												{{ FormMSG(5528, 'Currently you have no document') }}
											</div>
										</b-col>
									</b-overlay>
								</b-row>
							</b-card>
						</div>
					</transition>
				</div>
				<modal-for-sign-document
					:open="showModalForSignDocument"
					:document="documentSelected"
					:requester-id="requesterId"
					:user-id="userId"
					@modal-for-sign-document:document-signed="handleModalForSignDocumentDocumentSigned"
					@modal-for-sign-document:close="handleModalForSignDocumentClose"
				/>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(120, 'Success!')"
					v-model="successSignModal"
					class="modal-success"
					ok-variant="success"
					@ok="successSignModal = false"
					ok-only
				>
					{{ FormMSG(122, 'Your document has been signed.') }}
				</b-modal>
				<oodrive-sign-contract-modal
					v-model="isOodriveSignContractOpen"
					:url="urlOodrive"
					:delivery-id="documentSelected ? documentSelected.firstDeliveryId : 0"
					:doc-signed-xid="documentSelected ? documentSelected.docSignedXid : ''"
					@oodrive-sign-contract-modal:close="onOodriveSignContractClosed"
					@oodrive-sign-contract-modal:documentUpdateStatus="onOodriveSignContractStatusUpdated"
				/>
				<template #overlay>
					<div class="text-center">
						<loading :active.sync="isLoading" loader="bars" color="#00A09C" :width="64" />
						<p id="cancel-label" class="loading-text">
							{{ FormMSG(296, 'Please wait...') }}
						</p>
					</div>
				</template>
			</b-overlay>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';
import ListMyDocuments from '@/components/MyDocumentsMobile/ListMyDocuments';
import * as icons from 'lucide-vue';
import { classDocumentStatus } from '@/shared/global-status';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { getDocument, getDocumentList } from '@/modules/document-package/cruds/document.crud';
import ModalForSignDocument from '@/components/Modals/ModalForSignDocument';
import globalMixin from '@/mixins/global.mixin';
import { getOodriveSignatureLink } from '@/cruds/document.crud';
import OodriveSignContractModal from '@/components/Modals/OodriveSignContractModal';
import * as _ from 'lodash';
import { OODRIVE_VALID_DOCUMENT_EXT, EXT_TO_ACCEPT } from '@/shared/constants';
import InfoCard from '@/views/Premises/infoCard.vue';
import { getImgCommentList } from '@/cruds/images.crud';
import { mapActions } from 'vuex';

export default {
	name: 'MyDocuments',
	components: {
		Loading,
		ListMyDocuments,
		ModalForSignDocument,
		OodriveSignContractModal,
		InfoCard
	},
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hv: 'dark',
			erreur: {},
			Docs: [],
			curDoc: {},
			newDoc: {},
			currentPage: 1,
			currentIdx: 0,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			pdfFile: null,
			wmImageFile: null,
			displayAllCategories: false,
			stopPropagation: '',
			nativeEvent: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successSignModal: false,
			checkPasswordModal: false,
			firstTime: true,
			lastPublishedOn: '',
			passwordInput: '',
			passwordState: null,
			useDigElecSign: false,
			showModalAskPassword: false,
			showModalForSignDocument: false,
			documentSelected: null,
			requesterId: 0,
			userId: 0,
			isOodriveSignContractOpen: false,
			urlOodrive: '',
			isBusyDataTable: false
		};
	},
	computed: {
		optionsForDocType() {
			return this.FormMenu(1011);
		},
		docfields() {
			return [
				{
					key: 'name',
					label: this.FormMSG(30, 'Name'),
					sortable: true
				},
				{
					key: 'type',
					label: this.FormMSG(31, 'Type'),
					formatter: (value) => {
						const contractTypes = this.FormMenu(1011);
						const contractType = contractTypes.filter((contractType) => contractType.value === value);

						return contractType.length > 0 ? contractType[0].text : '';
					},
					sortable: true
				},
				{
					key: 'fileName',
					label: this.FormMSG(37, 'File name'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(38, 'From'),
					sortable: true
				},
				{
					key: 'publishedOn',
					label: this.FormMSG(39, 'Received'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY HH:mm');
					},
					class: 'text-center',
					sortable: true
				},
				{
					key: 'status',
					label: this.FormMSG(40, 'Status'),
					formatter: (v) => this.validatedStatus(v),
					class: 'text-center',
					sortable: true
				},
				// {
				// 	key: 'senderStatus',
				// 	label: this.FormMSG(199, 'Validation process'),
				// 	formatter: (value, key, item) => {
				// 		return this.getLabelStep(item.status, parseInt(value), item.deliveryCurrentStep, item.requireManagerApproval);
				// 	},
				// 	sortable: true,
				// 	class: 'text-center'
				// },
				{
					key: 'sign',
					label: this.FormMSG(47, 'Sign'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'viewDocument',
					label: this.FormMSG(297, 'View document'),
					class: 'text-center',
					sortable: false
				},
				{
					key: 'edit',
					label: this.FormMSG(41, 'Edit'),
					class: 'text-center',
					sortable: false
				}
			];
		},
		docMobileFields() {
			const keys = ['status', 'view', 'sign'];
			return this.docfields.filter((t) => !keys.includes(t.key));
		}
	},
	async created() {
		await this.reloadData();
		this.$previewerEventListener('mydocuments-previewer-elem', (e) => {
			const {
				on_documents_previewer_event__: { type }
			} = e;
			if (type === 'close') {
				this.$nextTick(async () => {
					await this.getNotifications();
					await this.reloadData();
				});
			}
		});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		getLabelStep(status, senderStatus, currentStep, requireManagerApproval) {
			if (senderStatus === 5) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}
			if (currentStep === 0) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}

			if (currentStep === 1) {
				if (!requireManagerApproval) {
					return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
				}
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(84, 'step 1');
			}
			if (currentStep === 2) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(85, 'step 2');
			}
			if (currentStep === 3) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(86, 'step 3');
			}
		},
		async handleModalForSignDocumentDocumentSigned() {
			await this.reloadData();
		},
		handleModalForSignDocumentClose() {
			this.showModalForSignDocument = false;
		},
		async handleListMyDocumentSign(payload) {
			await this.signDocument(payload);
		},
		/**
		 * @param {String or Number} value
		 * @return {String}
		 */
		validatedStatus(value) {
			const statuses = this.FormMenu(1012);
			const status = statuses.filter((status) => status.value === value);

			return status.length > 0 ? status[0].text : '';
		},
		docToSign() {
			//      console.log("indoctosign");
			if (this.newDoc.toSign && this.newDoc.status > 3) {
				// if need to sign and status == "read" 3
				return true;
			}
			return false;
		},
		colorStatus(status) {
			//console.log("color:", status);
			let retval = 'not-submitted'; // not-submitted
			if (status === 1) {
				// refused
				retval = 'refused';
			} else if (status === 2) {
				// pending
				retval = 'pending';
			} else if (status === 3) {
				// info
				retval = 'info';
			} else if (status === 4) {
				// validated
				retval = 'validated';
			}

			return retval;
		},
		setPublishedDate() {
			if (this.alreadyPublished()) {
				var date =
					this.newDoc.publishedOn.split('T')[0].substring(8) +
					'/' +
					this.newDoc.publishedOn.split('T')[0].substring(5, 7) +
					'/' +
					this.newDoc.publishedOn.split('T')[0].substring(0, 4);
				date = date + ' ' + this.newDoc.publishedOn.substring(11, 13) + ':' + this.newDoc.publishedOn.substring(14, 16);
				return '' + this.newDoc.publisher.name + ' ' + this.newDoc.publisher.firstName + '    - ' + date;
			} else {
				return 'NOT PUBLISHED YET';
			}
		},
		alreadyPublished() {
			var y = new Date('2013-05-23');
			var x = new Date(this.newDoc.publishedOn.substring(0, 10));
			if (x.getTime() > y.getTime()) {
				return true;
			} else {
				return false;
			}
		},
		viewDocumentCertificate(item) {
			// console.log('in viewdocument certifacte')
			if (this.curDoc.toSign) {
				// needs to generate new doc for specific user, watermark specific or contract template
				//console.log("viewdocument  item.useWatermark && item.watermarkWithUserbefore userId:",item);
				var tmp = store.userID();
				var urlStr =
					process.env.VUE_APP_GQL +
					'/GetDocumentCertificate/' +
					tmp +
					'/' +
					this.curDoc.id +
					'/' +
					item.deliveryContractId +
					'/Certificate' +
					Date.now().toString() +
					'.docx';

				console.log('viewDocumentCertificate url:', urlStr);
				window.open(urlStr); // in new window
			}
			return;
		},
		viewDocument(item) {
			// console.log('in viewdocument item:', item)
			if ((item.useWatermark && item.watermarkWithUser) || item.type == 9 || item.toSign) {
				// needs to generate new doc for specific user, watermark specific or contract template
				//console.log("viewdocument  item.useWatermark && item.watermarkWithUserbefore userId:",item);
				var tmp = store.userID();
				var urlStr = process.env.VUE_APP_GQL + '/documentForUser/' + tmp + '/' + tmp + '/' + item.xid;
			} else if (item.xidGenerated.length > 0) {
				this.sendReadReceipt(item);
				var urlStr = process.env.VUE_APP_GQL + '/images/' + item.xidGenerated;
			} else if (item.xid.length > 0) {
				this.sendReadReceipt(item);
				var urlStr = process.env.VUE_APP_GQL + '/images/' + item.xid;
			} else {
				return;
			}
			// mark document as read update status and color
			if (item.status < 3) {
				item.status = 3;
				item._cellVariants = {
					status: this.colorStatus(item.status)
				};
			}
			// console.log('viewdocument url:', urlStr)
			window.open(urlStr); // in new window
			return;
		},
		viewDocumentDetail(item) {
			const documentLink = `/mydocuments/document-details/${item.id}/${item.deliveryContractId}`;
			// console.log("starting:", documentLink);
			this.$router.push({
				path: documentLink
			});
		},
		initValuesForNoRecords() {
			this.newDoc.name = '?';
			this.newDoc.fileName = '';
			this.newDoc.type = parseInt('0', 10);
			this.newDoc.fileName = '';
			this.newDoc.userPassword = '';
			this.newDoc.ownerPassword = '';
			this.newDoc.xid = '';
			this.newDoc.useWatermark = false;
			this.newDoc.watermarkText = '';
			this.newDoc.watermarkPictureXid = '';
			this.newDoc.watermarkPictureFileName = '';
			this.newDoc.watermarkWithUser = false;
			this.newDoc.toSign = false;
			this.newDoc.toEmail = false;
			this.newDoc.availableOnline = true;
			this.newDoc.userSpecificXid = '';
		},
		sendReadReceipt(item) {
			// // console.log('sendReadReceipt item :', item)
			// // only send read receipt if no document attached otherwise sendReadReceipt is generated during document upload
			// if (item.status >= 3) {
			// 	// already read...
			// 	return
			// }
			// item.status = 3
			// item._cellVariants = {
			// 	status: this.colorStatus(item.status)
			// }
			// var tmp = store.userID()
			// var urlStr = process.env.VUE_APP_GQL + '/documentReadReceipt/' + item.replyXid
			// axios
			// 	.get(urlStr)
			// 	.then((response) => {
			// 		// console.log('sendReadReceipt returns:', response)
			// 	})
			// 	.catch((error) => {
			// 		console.log(error)
			// 	})
			// return
		},
		async reloadData() {
			try {
				this.isBusyDataTable = true;
				const result = await getDocumentList({
					userId: store.userID(),
					showOnlyMyDocument: true
				});

				this.Docs = result;

				for (let i = 0; i < this.Docs.length; i++) {
					this.Docs[i].fullName = this.Docs[i].publisher.name + ' ' + this.Docs[i].publisher.firstName;
					this.Docs[i].validatedClass = classDocumentStatus(this.Docs[i].status);
					this.Docs[i].prodValidatedClass = classDocumentStatus(this.Docs[i].senderStatus);
					this.Docs[i].src = getFileSrc(this.Docs[i], {
						imageOnly: !this.Docs[i].type === 11,
						contractId: this.Docs[i].deliveryContractId
					});
					this.Docs[i].thumb = process.env.VUE_APP_PATH_IMG_THUMB_URL + this.Docs[i].xid;
					this.Docs[i].ext = getFileExtension(this.Docs[i].fileName);
					this.Docs[i].fileName = this.replaceToRightFileNameExt(this.Docs[i].fileName, this.Docs[i].docSignedXid);
					if (this.Docs[i].senderStatus < 2 || this.Docs[i].senderStatus > 6) {
						let comments = await getImgCommentList(this.Docs[i].xid, this.Docs[i].firstDeliveryId);
						let highestIdComment = comments.reduce((highestComment, currentComment) => {
							// Parse the IDs before comparison
							const highestCommentId = parseInt(highestComment.id, 10);
							const currentCommentId = parseInt(currentComment.id, 10);

							// Compare the parsed IDs
							return currentCommentId > highestCommentId ? currentComment : highestComment;
						}, comments[0]); // Initialize with the first comment

						this.Docs[i].comments =
							highestIdComment && highestIdComment.message && !_.isEmpty(highestIdComment.message) ? highestIdComment.message : '';
					}
				}
				// console.log({ docs: this.Docs });

				if (this.Docs.length > 0) {
					this.curDoc = this.Docs[0];
					this.newDoc = Object.assign({}, this.curDoc);
					var idx = store.getCurrentUserDocumentTableIndex();
					//console.log("var idx = store.state.getCurrentUserDocumentTableIndex", idx);
					if (this.$screen.width > 567) this.clickRow(idx, this.Docs[0]);
					this.firstTime = false;
				} else {
					this.initValuesForNoRecords();
				}
				this.isBusyDataTable = false;
			} catch (e) {
				this.erreur = e;
			}
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item, index) {
			// compute currentIdx
			this.currentIdx = (this.currentPage - 1) * this.perPage + index;
			store.setCurrentUserDocumentTableIndex(this.currentIdx);
			//console.log("item in rowClicked index:", this.currentIdx);
			this.curDoc = item;
			//console.log("curdoc in rowclicked:",	this.curDoc)
			//console.log("item:",item)
			this.newDoc = Object.assign({}, item);
			this.lastPublishedOn = this.setPublishedDate();
			//  console.log("item in rowClicked  this.$refs.myTable:",  this.$refs.myTable);
			if (item.status < 3 && item.fileName.length == 0) {
				this.sendReadReceipt(item);
			}
			if (this.$screen.width < 992) {
				this.scrollUp();
			}
		},
		clickRow(idx, item) {
			// idx is the index in the Docs array
			this.lastPublishedOn = this.setPublishedDate();
			this.currentPage = 1;
			//  parseInt(parseInt(idx, 10) / parseInt(this.perPage, 10), 10) + 1;
			store.setCurrentUserDocumentTableIndex(idx);
			//console.log("in clickRow before:", item);
			if (item.status < 3 && item.fileName.length == 0) {
				this.sendReadReceipt(item);
			}
			this.$nextTick(() => {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				if (this.$refs.myTable) {
					let myTable = this.$refs.myTable.$el,
						tableBody = myTable.getElementsByTagName('tbody')[0],
						tableRows = tableBody.getElementsByTagName('tr');
					tableRows[idx].click();
				}
			});
		},
		async signDocument(item) {
			this.documentSelected = item;
			if (OODRIVE_VALID_DOCUMENT_EXT.includes(this.documentSelected.ext)) {
				this.isLoading = true;
				await getOodriveSignatureLink(this.documentSelected.firstDeliveryId)
					.then(async (result) => {
						this.isLoading = false;
						this.urlOodrive = result;
						this.isOodriveSignContractOpen = true;
						// await this.reloadData();
					})
					.catch((error) => {
						console.log({ error });
						this.isLoading = false;
					})
					.finally(() => {
						this.isLoading = false;
					});
			} else {
				this.showModalForSignDocument = true;
				this.requesterId = store.userID();
				this.userId = store.userID();
				await this.reloadData();
			}
		},
		downloadESA() {
			this.isLoading = true;
			this.warning = '';
			var tmp = store.userID();
			var urlStr = process.env.VUE_APP_GQL + '/ElectronicSignatureAgreement/' + tmp;
			//console.log("viewElectronicSignAgreement url:", urlStr);
			window.open(urlStr); // in new window
			this.isLoading = false;
			return;
		},
		scrollUp() {
			window.scrollTo(document.body.scrollHeight || document.documentElement.scrollHeight, 0);
		},
		clearFilter() {
			if (this.filter !== '') {
				this.filter = '';
				// this.handleInputFilter('')
			}
		},
		getIconLucide(name) {
			return icons[name];
		},
		async handlePreviewDocument(file) {
			await getDocument(file.id, { showOnlyMyDocument: true }, store.userID(), file.deliveryContractId).then((record) => {
				let fileList = [];
				if (record.type === 11) {
					// store.state.myProfile.id
					fileList = record.childDocList.filter((list) => list.userId === null || parseInt(list.userId, 10) === 0);
					fileList = fileList.map((doc) => {
						const src = getFileSrc(doc, { contractId: doc.deliveryContractId });
						return {
							...doc,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							src: `${src}?deliveryParentId=${doc.firstDeliveryParentId}`,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(doc.xid, doc.docSignedXid),
							status: EXT_TO_ACCEPT.includes(getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)))
								? this.manageStatusInAdvance(doc.status)
								: doc.status,
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							isHovered: false
						};
					});
				} else {
					const src = getFileSrc(record, {
						imageOnly: false,
						contractId: record.deliveryContractId
					});
					fileList.push({
						...record,
						fileName: this.replaceToRightFileNameExt(record.fileName, record.docSignedXid),
						type: record.type,
						xid: this.getRightXidDocument(record.xid, record.docSignedXid),
						status: EXT_TO_ACCEPT.includes(getFileExtension(this.getRightXidDocument(record.xid, record.docSignedXid)))
							? this.manageStatusInAdvance(record.status)
							: record.status,
						toSign: record.toSign,
						src: `${src}?deliveryParentId=${record.firstDeliveryParentId}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(record.xid, record.docSignedXid),
						ext: getFileExtension(this.getRightXidDocument(record.xid, record.docSignedXid)),
						isHovered: false
					});
				}
				this.$previewImages({
					name: 'mydocuments-previewer-elem',
					images: fileList,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						showStatusFileTag: true,
						hideCommentButton: false,
						hideDisLikeButton: false,
						hideLikeDislike: false,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: true
					}
				});
			});
		},
		dateToString(date) {
			return new moment(date).utc().format('DD/MM/YYYY');
		},
		onOodriveSignContractClosed() {
			this.isOodriveSignContractOpen = false;
		},
		async onOodriveSignContractStatusUpdated(payload) {
			if (!_.isNil(payload)) {
				await this.getNotifications();
				await this.reloadData();
			}
		}
	}
};
</script>
<style scoped>
.loading-text {
	color: #06263e;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
}
</style>
