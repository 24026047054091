<template>
	<div class="container-mobile animated fadeIn" style="top: 95px">
		<div class="content-contact-mobile content-document-mobile form">
			<b-row>
				<b-col style="display: flex">
					<div class="form-input-search">
						<b-form-input
							v-model="filter"
							type="text"
							:placeholder="FormMSG(1, 'Type to search')"
							@input="handleInputFilter"
							class="search"
						></b-form-input>
					</div>
					<div class="form-input-icon-search" style="cursor: pointer" @click="clearFilter">
						<search v-if="filter === ''" color="#06263E" :size="16" />
						<x v-else color="#06263E" :size="16" />
					</div>
				</b-col>
			</b-row>
			<b-row style="margin-top: 16px">
				<b-col>
					<b-form-select v-model="docTypeSelected" class="mb-3" @change="handleChangeDocType">
						<b-form-select-option :value="null">{{ FormMSG(4, 'All') }}</b-form-select-option>
						<b-form-select-option v-for="(docType, index) in docTypes" :key="index" :value="docType.value">
							{{ docType.text }}
						</b-form-select-option>
					</b-form-select>
				</b-col>
			</b-row>
			<b-row style="margin-top: 16px">
				<b-col>
					<div class="title-list">
						<h2>{{ FormMSG(2, 'Files') }}</h2>
					</div>
				</b-col>
			</b-row>
			<div v-if="docs.length === 0" class="text-center">
				<p>{{ FormMSG(3, 'No result') }}</p>
			</div>
			<div v-for="(doc, index) in docs" :key="index">
				<div v-if="index > 0" class="divider-line mt-3 mb-2" />
				<b-row :class="{ 'mt-3': index === 0 }">
					<b-col cols="10" class="pr-0 d-flex flex-column justify-content-start">
						<p class="name-contact mt-1 mb-0">
							<label>
								{{ doc.name }}
							</label>
						</p>
						<p class="mb-1" v-if="doc.type === 11 || doc.type === 9">
							{{ dateToString(doc.deliveryContractStrDate) }} -
							{{ dateToString(doc.deliveryContractEndDate) }}
						</p>
						<p class="mt-2 mb-0">
							<span class="status info">
								{{ getDocType(doc.type) }}
							</span>
							<span class="divider-dot" />
							{{ getPublishedOn(doc.publishedOn) }}
						</p>
						<div>
							<Button v-if="doc.comments" class="mt-3 mb-0 p-0 p-button-text" v-tooltip.focus="doc.comments">
								<span class="wrap-status d-flex justify-content-start">
									<span :class="`status ${doc.validatedClass}`" style="font-size: 0.7rem">
										{{ validatedStatus(doc.status) }}
									</span>
								</span>
							</Button>
							<Button v-else class="mt-3 mb-0 p-0 p-button-text">
								<span class="wrap-status d-flex justify-content-start">
									<span :class="`status ${doc.validatedClass}`" style="font-size: 0.7rem">
										{{ validatedStatus(doc.status) }}
									</span>
								</span>
							</Button>
						</div>
					</b-col>
					<b-col cols="2" class="d-flex align-items-start">
						<b-dropdown variant="light" toggle-class="transparent" no-caret dropleft>
							<template #button-content>
								<MoreVertical color="#06263E" :size="20" />
							</template>
							<b-dropdown-item @click="viewDocument(doc)">
								<div class="d-flex justify-content-start align-items-center w-100">
									<component :is="getLucideIcon('Eye')" color="#06263E" :size="16" />
									<div class="pl-2">
										{{ FormMSG(7, 'View document') }}
									</div>
								</div>
							</b-dropdown-item>
							<b-dropdown-item @click="viewDocumentDetail(doc)">
								<div class="d-flex justify-content-start align-items-center w-100">
									<component :is="getLucideIcon('Edit2')" color="#06263E" :size="16" />
									<div class="pl-2">
										{{ FormMSG(5, 'See Details') }}
									</div>
								</div>
							</b-dropdown-item>
							<b-dropdown-item v-if="doc.status === 3 && doc.toSign" @click="handleClickSign(doc)">
								<div class="d-flex justify-content-start align-items-center w-100">
									<component :is="getLucideIcon('FileSignature')" color="#06263E" :size="16" />
									<div class="pl-2">
										{{ FormMSG(15, 'Sign') }}
									</div>
								</div>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import Button from 'primevue/button';
import { Search, X, MoreVertical } from 'lucide-vue';
import { store } from '@/store';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import * as icons from 'lucide-vue';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { getDocument } from '@/modules/document-package/cruds/document.crud';
import { EXT_TO_ACCEPT } from '@/shared/constants';
import _ from 'lodash';

export default {
	name: 'ListMyDocuments',

	components: {
		Search,
		X,
		MoreVertical,
		Button
	},
	mixins: [languageMessages, GlobalMixin],
	props: {
		documents: { type: Array, default: () => [] }
	},
	data() {
		return {
			filter: '',
			docs: [],
			originalDocs: [],
			docTypeSelected: null,
			showDialogDetail: false,
			documentSelected: {}
		};
	},
	computed: {
		docTypes() {
			return this.FormMenu(1011);
		}
	},
	watch: {
		documents: {
			handler(val) {
				this.docs = [];
				setTimeout(() => {
					this.docs = _.cloneDeep(val);
					this.originalDocs = _.cloneDeep(val);
				}, 250);
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		moment.locale(this.lang);
	},
	methods: {
		handleClickSign(document) {
			this.$emit('list-my-document:sign', document);
		},
		handleInputFilter(value) {
			let filter = value.trim().toLowerCase();
			if (filter === '') this.handleChangeDocType(this.docTypeSelected);
			let arr = this.docs.filter((doc) => doc.name.toLowerCase().indexOf(filter) > -1);
			this.docs = arr;
		},
		async viewDocument(file) {
			await getDocument(file.id, { showOnlyMyDocument: true }, store.userID(), file.deliveryContractId).then((record) => {
				let fileList = [];
				if (record.type === 11) {
					// store.state.myProfile.id
					fileList = record.childDocList.filter((list) => list.userId === null || parseInt(list.userId, 10) === 0);
					fileList = fileList.map((doc) => {
						const src = getFileSrc(doc, { contractId: doc.deliveryContractId });
						return {
							...doc,
							fileName: this.replaceToRightFileNameExt(doc.fileName, doc.docSignedXid),
							src: `${src}?deliveryParentId=${doc.firstDeliveryParentId}`,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(doc.xid, doc.docSignedXid),
							status: EXT_TO_ACCEPT.includes(getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)))
								? this.manageStatusInAdvance(doc.status)
								: doc.status,
							ext: getFileExtension(this.getRightXidDocument(doc.xid, doc.docSignedXid)),
							isHovered: false
						};
					});
				} else {
					const src = getFileSrc(record, {
						imageOnly: false,
						contractId: record.deliveryContractId
					});
					fileList.push({
						...record,
						fileName: this.replaceToRightFileNameExt(record.fileName, record.docSignedXid),
						type: record.type,
						xid: this.getRightXidDocument(record.xid, record.docSignedXid),
						status: EXT_TO_ACCEPT.includes(getFileExtension(this.getRightXidDocument(record.xid, record.docSignedXid)))
							? this.manageStatusInAdvance(record.status)
							: record.status,
						toSign: record.toSign,
						src: `${src}?deliveryParentId=${record.firstDeliveryParentId}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.getRightXidDocument(record.xid, record.docSignedXid),
						ext: getFileExtension(this.getRightXidDocument(record.xid, record.docSignedXid)),
						isHovered: false
					});
				}
				this.$previewImages({
					name: 'mydocuments-previewer-elem',
					images: fileList,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						showStatusFileTag: true,
						hideCommentButton: false,
						hideDisLikeButton: false,
						hideLikeDislike: false,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: true
					}
				});
			});
		},
		showModal(document) {
			console.log({ document });
			this.documentSelected = document;
			this.showDialogDetail = true;
		},
		closeModal() {
			this.showDialogDetail = false;
		},
		handleChangeDocType(payload) {
			this.clearFilter();
			if (payload === null) {
				this.docs = this.originalDocs;
			} else {
				this.docs = this.originalDocs.filter((oD) => parseInt(oD.type) === parseInt(payload));
			}
		},
		getPublishedOn(value) {
			return moment(value).utc().format('DD MMMM YYYY');
		},
		getDocType(value) {
			const findIndex = this.docTypes.findIndex((d) => parseInt(d.value) === parseInt(value));
			if (findIndex !== -1) {
				return this.docTypes[findIndex].text;
			}
			return '';
		},
		clearFilter() {
			if (this.filter !== '') {
				this.filter = '';
				this.handleInputFilter('');
			}
		},
		validatedStatus(value) {
			const statuses = this.FormMenu(1012);
			const status = statuses.filter((status) => status.value === value);

			return status.length > 0 ? status[0].text : '';
		},
		getLucideIcon(name) {
			return icons[name];
		},
		viewDocumentDetail(item) {
			const documentLink = `/mydocuments/document-details/${item.id}/${item.deliveryContractId}`;
			// console.log("starting:", documentLink);
			this.$router.push({
				path: documentLink
			});
		},
		dateToString(date) {
			return new moment(date).utc().format('DD/MM/YYYY');
		}
	}
};
</script>
